import { IShipment, IShipmentEndMethod, ShipmentStatus } from "../models/ShipmentsView";
import { formatTimestamp } from "./DateUtils";
import { getDisplayNameForAppHealth, getDisplayTextForShipmentStatus } from "./DisplayUtils";

export function getShipmentsListDataForExport(
  shipmentListViews: IShipment[]
): { headers: { label: string; key: string }[]; rows: any[] } {
  const rows: {
    shipmentId: string;
    truckNumber: string;
    driverName: string;
    associatedEntity: string;
    driverMobileNumber: string;
    from: string;
    to: string;
    creationTime?: string;
    lastUpdatedOn?: string;
    appStatus: string;
    tripCompletion: string;
    status: string;
  }[] = [];

  shipmentListViews.sort((a, b) => {
    return a.creationTime > b.creationTime ? 1 : -1;
  });

  shipmentListViews.forEach((shipmentListViews) => {
    const row = {
      shipmentId: shipmentListViews.vanityId,
      truckNumber: shipmentListViews.vehicleNumber,
      driverName: shipmentListViews.driverName,
      associatedEntity: shipmentListViews.associatedEntity,
      driverMobileNumber: shipmentListViews.driverMobileNumber,
      from: shipmentListViews.shipmentStartCity,
      to: shipmentListViews.shipmentDestinationCity,
      creationTime: formatTimestamp(shipmentListViews.creationTime),
      lastUpdatedOn: formatTimestamp(shipmentListViews.lastLiveLocationReceivedTime),
      appStatus: getDisplayNameForAppHealth(shipmentListViews.appHealth),
      tripCompletion: `${shipmentListViews.shipmentCompletionPercentage.toFixed(0)} %`,
      status: `${getDisplayTextForShipmentStatus(shipmentListViews.status)} ${shipmentListViews.status === ShipmentStatus.COMPLETE && shipmentListViews.shipmentEndMethod === IShipmentEndMethod.MANUAL ? "(M)" : ""}`
    };
    rows.push(row);
  });

  return {
    headers: [
      { label: "Shipment ID", key: "shipmentId" },
      { label: "Truck No", key: "truckNumber" },
      { label: "Driver Name", key: "driverName" },
      { label: "Branch / Transporter", key: "associatedEntity" },
      { label: "Driver Mobile Number", key: "driverMobileNumber" },
      { label: "From", key: "from" },
      { label: "To", key: "to" },
      { label: "Creation Time", key: "creationTime" },
      { label: "Last Updated On", key: "lastUpdatedOn" },
      { label: "App Status", key: "appStatus" },
      { label: "Trip Completion", key: "tripCompletion" },
      { label: "Status", key: "status" },
    ],
    rows,
  };
}
