import { Box, Button, Card, CardContent, CircularProgress, Grid, MenuItem, TextField, Tooltip } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getShipments, createShipmentShareLink, changeShipmentPriority } from '../../api/ShipmentsListApi';
import Toast, { IToastBasicProps } from '../../components/Toast/Toast';
import { HumsaferThemeContext } from '../../contexts/HumsaferThemeContext';
import { HumsaferError } from '../../models/HumsaferError';
import { IShipment, IDateRange, IFilterStatus, AppHealth, ShipmentTrackingStatus, ShipmentStatus, ShipmentPriority } from '../../models/ShipmentsView';
import styles from "./ShipmentsList.module.css";
import RefreshIcon from '@mui/icons-material/Refresh';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useForm } from 'react-hook-form';
import { Loading } from '../../components/loading/Loading';
import { useMediaQuery, Theme } from '@mui/material';
import { formatDateForBackend, formatTimestamp } from '../../utils/DateUtils';
import ReportIcon from '@mui/icons-material/Report';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import GenericTable from '../../components/genericTable/GenericTable';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import ProgressBar from '../../components/progressBar/ProgressBar';
import ActionMenu from "./ShipmentsActionMenu"
import CompanyInfoWithSwitcher from '../../components/companyInfoWithSwitcher/CompanyInfoWithSwitcher';
import { isAllowedToSwitchCompanies } from '../../utils/CapabitilityUtils';
import { CompanyDataContext } from '../../contexts/CompanyDataContext';
import { Auth } from '../../utils/Auth';
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { getDisplayDateRange } from '../../utils/DisplayUtils';
import CSVDownload from '../../components/utils/CSVDownload';
import { getShipmentsListDataForExport } from '../../utils/CSVExportUtils';
import moment from 'moment';
import HumsaferDialog from '../../components/humsaferDialog/HumsaferDialog';

const ShipmentList: React.FC = React.memo(() => {
    const [shipments, setShipments] = useState<IShipment[]>();
    const [isFetching, setIsFetching] = useState(false);
    const [dateRange, setDateRange] = useState<IDateRange>(() => window.localStorage.getItem("dateRange") as IDateRange || IDateRange.LAST_WEEK);
    const [inTransitCount, setInTransitCount] = useState(0)
    const [appStatusUnhealthyCount, setAppStatusUnhealthyCount] = useState(0)
    const [priorityCount, setPriorityCount] = useState(0)
    const [completeCount, setCompleteCount] = useState(0)
    const [filterStatus, setFilterStatus] = useState<IFilterStatus>(() => window.localStorage.getItem("filterStatus") as IFilterStatus || IFilterStatus.ALL);
    const [allShipments, setAllShipments] = useState<IShipment[]>([]);
    const [filteredAllShipments, setFilteredAllShipments] = useState<IShipment[]>([]);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [showToast, setShowToast] = useState<IToastBasicProps>({
        open: false,
        message: "",
        type: "success",
    });
    const [isFilter, setIsFilter] = useState(false);
    const { primaryColor } = useContext(HumsaferThemeContext);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [searchQuery, setSearchQuery] = useState('');
    const [shipmentStartCity, setShipmentStartCity] = useState<string[]>([]);
    const [shipmentDestinationCity, setShipmentDestinationCity] = useState<string[]>([]);
    const [shipmentTransporter, setShipmentTransporter] = useState<string[]>([]);
    const [isPrioritized, setIsPrioritized] = useState<boolean>(false);
    const [isCopyShareLink, setIsCopyShareLink] = useState<boolean>(false);
    const [untrackedShipmentCount, setUntrackedShipmentCount] = useState(0);
    const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);


    const { selectedCompany } = useContext(CompanyDataContext);

    const allowCompanySwitcher = isAllowedToSwitchCompanies();

    const onSubmitFilter = async (props) => {
        const { from, to, transporter } = props;

        // Check if at least one field has a value
        if (!from && !to && !transporter) {
            setShowToast({
                open: true,
                message: `Please select at least one filter`,
                type: "error"
            });
            return;
        }

        // Filter shipments based on the selected criteria
        const filteredShipments = filteredAllShipments.filter(shipment =>
            (!from || shipment.shipmentStartCity === from) &&
            (!to || shipment.shipmentDestinationCity === to) &&
            (!transporter || shipment.associatedEntity === transporter)
        );

        setShipments(filteredShipments);
    }

    const searchFilter = (e) => {
        setSearchQuery(e.target.value)
        applyFilter(filterStatus, e.target.value, allShipments);
    };

    const applyFilter = useCallback(async (filterStatus: IFilterStatus, searchQuery: string, allShipmentsList: IShipment[]) => {
        let filteredShipments = [...allShipmentsList];
        if (filterStatus !== IFilterStatus.ALL) {
            filteredShipments = filteredShipments.filter(item => {
                if (filterStatus === IFilterStatus.IN_TRANSIT) {
                    return item.status === ShipmentStatus.IN_TRANSIT;
                } else if (filterStatus === IFilterStatus.COMPLETED) {
                    return item.status === ShipmentStatus.COMPLETE;
                } else if (filterStatus === IFilterStatus.PRIORITIZED) {
                    return item.priority === ShipmentPriority.PRIORITIZED;
                } else if (filterStatus === IFilterStatus.APP_STATUS_UNHEALTHY) {
                    return (item.status !== ShipmentStatus.COMPLETE && item.status !== ShipmentStatus.CANCELLED) && item.appHealth !== AppHealth.HEALTHY;
                } else if (filterStatus === IFilterStatus.UNTRACKED) {
                    if (item.status !== ShipmentStatus.COMPLETE && item.status !== ShipmentStatus.CANCELLED) {
                        return item.shipmentTrackingStatus === ShipmentTrackingStatus.MISSING_TRACKING_INFO;
                    } else {
                        return false;
                    }
                }
                return true;
            });
        }

        if (searchQuery && typeof searchQuery === 'string') {
            filteredShipments = filteredShipments.filter(item => {
                const vanityId = item.vanityId ? item.vanityId.toLowerCase() : "";
                const driverName = item.driverName ? item.driverName.toLowerCase() : "";
                const driverMobileNumber = item.driverMobileNumber ?? "";
                const vehicleNumber = item.vehicleNumber.toLowerCase() ?? "";

                const normalizedSearchQuery = searchQuery.toLowerCase().trim();

                return vanityId.includes(normalizedSearchQuery)
                    || driverName.includes(normalizedSearchQuery)
                    || driverMobileNumber.includes(normalizedSearchQuery)
                    || vehicleNumber.includes(normalizedSearchQuery);
            });
        }

        setShipments(filteredShipments);
        setFilteredAllShipments(filteredShipments)
    }, []);

    const fetchShipment = useCallback(async () => {

        let companyId = Auth.getInstance().getCompanySettings()?.id;
        if (selectedCompany) {
            companyId = selectedCompany.id;
        }

        if (companyId === undefined) {
            return
        }

        let date = formatDateForBackend(new Date());
        switch (dateRange) {
            case IDateRange.TODAY:
                date = formatDateForBackend(new Date());
                break;
            case IDateRange.LAST_WEEK:
                date = formatDateForBackend(new Date(new Date().setDate(new Date().getDate() - 7)));
                break;
            case IDateRange.LAST_MONTH:
                date = formatDateForBackend(new Date(new Date().setMonth(new Date().getMonth() - 1)));
                break;
        }

        setIsFetching(true);
        const shipmentOrError = await getShipments(companyId, date);
        setIsFetching(false);
        if (shipmentOrError instanceof HumsaferError) {
            setShowToast({
                open: true,
                message: shipmentOrError.message,
                type: "error"
            });
            return;
        }

        setShipments(shipmentOrError);
        setAllShipments(shipmentOrError);
        setInTransitCount(shipmentOrError.filter(item => item.status === ShipmentStatus.IN_TRANSIT).length);
        setAppStatusUnhealthyCount(shipmentOrError.filter(item => item.status !== ShipmentStatus.COMPLETE && item.status !== ShipmentStatus.CANCELLED && item.appHealth !== AppHealth.HEALTHY).length);
        setPriorityCount(shipmentOrError.filter(item => item.priority === ShipmentPriority.PRIORITIZED).length);
        setCompleteCount(shipmentOrError.filter(item => item.status === ShipmentStatus.COMPLETE).length);
        setUntrackedShipmentCount(shipmentOrError
            .filter(item => item.status !== ShipmentStatus.COMPLETE && item.status !== ShipmentStatus.CANCELLED)
            .filter(item => item.shipmentTrackingStatus === ShipmentTrackingStatus.MISSING_TRACKING_INFO)
            .length)
        setShipmentStartCity(Array.from(new Set(shipmentOrError.map(shipment => shipment.shipmentStartCity).filter(city => city !== null))));
        setShipmentDestinationCity(Array.from(new Set(shipmentOrError.map(shipment => shipment.shipmentDestinationCity).filter(city => city !== null))));

        setShipmentTransporter(Array.from(new Set(shipmentOrError.map(shipment => shipment.associatedEntity).filter(transporter => transporter !== null && transporter !== ""))));
    }, [dateRange, selectedCompany]);


    useEffect(() => {
        fetchShipment();
    }, [fetchShipment]);

    useEffect(() => {
        applyFilter(filterStatus, searchQuery, allShipments);
    }, [filterStatus, searchQuery, allShipments, applyFilter])

    const handleFilterStatusChange = (newFilterStatus: IFilterStatus) => {
        setFilterStatus(newFilterStatus);
        window.localStorage.setItem("filterStatus", newFilterStatus)
    }

    const handleToastClose = () => {
        setShowToast({
            open: false,
            message: showToast.message,
            type: showToast.type,
        });
    };

    const handleCopyShareLink = async (shipment: IShipment) => {
        setIsCopyShareLink(true);
        const shipmentOrError = await createShipmentShareLink(shipment.companyId, shipment.id);
        setIsCopyShareLink(false);
        if (shipmentOrError instanceof HumsaferError) {
            setShowToast({
                open: true,
                message: shipmentOrError.message,
                type: "error"
            });
            return;
        }
        await navigator.clipboard.writeText(shipmentOrError.shareLink);
        setShowToast({
            open: true,
            message: "Shipment link copied",
            type: "success"
        });
    }

    const handlePrioritised = async (shipment: IShipment) => {
        setIsPrioritized(true);
        const setPriority = shipment.priority === ShipmentPriority.DEFAULT ? ShipmentPriority.PRIORITIZED : ShipmentPriority.DEFAULT;
        const shipmentOrError = await changeShipmentPriority(shipment.companyId, shipment.id, setPriority);
        setIsPrioritized(false);
        if (shipmentOrError instanceof HumsaferError) {
            setShowToast({
                open: true,
                message: shipmentOrError.message,
                type: "error"
            });
            return;
        }
        fetchShipment();
        setShowToast({
            open: true,
            message: `Shipment priority ${setPriority} set successfully`,
            type: "success"
        });
    }

    const handelViewDetails = (data: IShipment) => {
        window.open(`/shipments/${data.id}`, '_blank');
    }

    const refreshShipment = () => {
        setSearchQuery("");
        fetchShipment();
    }

    const handleDateRangeChange = (dateRange: IDateRange) => {
        if (dateRange !== IDateRange.LAST_MONTH) {
            setDateRange(dateRange);
            window.localStorage.setItem("dateRange", dateRange)
        } else {
            setShowSubscriptionDialog(true)
        }

    }

    return (
        <>
            <Toast
                message={showToast.message}
                open={showToast.open}
                onClose={handleToastClose}
                type={showToast.type}
            />
            {isFetching && (
                <div className={styles.shipment_details_loader}>
                    <CircularProgress size={25} />
                </div>
            )}

            {!isFetching && shipments && (
                <>
                    <HumsaferDialog
                        title="Subscription Required"
                        description={(
                            <p className={styles.shipment_subscription_message}>
                                Access to this data is available exclusively to our paid subscribers. Upgrade your subscription to view the monthly data.
                            </p>
                        )}
                        buttonText={"Okay"}
                        isOpen={showSubscriptionDialog}
                        closeDialog={() => setShowSubscriptionDialog(false)}
                    />
                    {allowCompanySwitcher && <CompanyInfoWithSwitcher />}
                    <Grid container>
                        <Box sx={{ flexGrow: 1 }} className={styles.shipment_top_bar}>
                            <Grid container spacing={1} className={styles.shipment_top_bar_align}>
                                <Grid item xs={6} md={4}>
                                    <div className={styles.shipment_title_align}>
                                        <span className={styles.shipment_top_bar_text}>
                                            All Shipments
                                        </span>
                                    </div>
                                </Grid>
                                {isMobile && (<Grid item xs={6} md={3}>
                                    <div>
                                        <Button
                                            variant="text"
                                            className={styles.shipment_top_bar_refresh}
                                            onClick={refreshShipment}
                                        >
                                            <RefreshIcon />Refresh
                                        </Button>
                                    </div>
                                </Grid>)}

                                <Grid item xs={12} md={4} >
                                    <div className={styles.shipment_buttons_align}>
                                        <Button className={dateRange === IDateRange.TODAY ? styles.shipment_top_bar_button_active : styles.shipment_top_bar_button} onClick={() => { handleDateRangeChange(IDateRange.TODAY) }} variant="text">Today</Button>
                                        <Button className={dateRange === IDateRange.LAST_WEEK ? styles.shipment_top_bar_button_active : styles.shipment_top_bar_button} onClick={() => { handleDateRangeChange(IDateRange.LAST_WEEK) }} variant="text">Past 7 days</Button>
                                        {/* <Button className={dateRange === IDateRange.LAST_MONTH ? styles.shipment_top_bar_button_active : styles.shipment_top_bar_button} onClick={() => { handleDateRangeChange(IDateRange.LAST_MONTH) }} variant="text"> Past 1 month</Button> */}
                                    </div>
                                </Grid>
                                {!isMobile && (<Grid item xs={6} md={4}>
                                    <div>
                                        <Button
                                            variant="text"
                                            className={styles.shipment_top_bar_refresh}
                                            onClick={refreshShipment}
                                        >
                                            <RefreshIcon />Refresh
                                        </Button>
                                    </div>
                                </Grid>)}

                            </Grid>

                        </Box>
                    </Grid>

                    <Grid container>
                        <Box sx={{ flexGrow: 1 }} className={styles.shipment_top_bar}>
                            <Grid container spacing={1} >
                                <Grid item xs={12} md={12}>
                                    <div className={styles.shipment_top_bar_search}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            onChange={searchFilter}
                                            value={searchQuery}
                                            placeholder="Search for Shipment ID, Vehicle number, Driver name, Driver Mobile Number ..."
                                            sx={{
                                                "& .MuiInput-underline:before": {
                                                    borderBottom: "none"
                                                },
                                                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                                                    borderBottom: "none"
                                                },
                                                "& .MuiInput-underline:after": {
                                                    borderBottom: "none",
                                                    content: "none"
                                                },
                                                backgroundColor: "white"
                                            }}
                                            InputProps={{
                                                startAdornment: (<>
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>

                                                </>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment className={styles.shipment_search_filter_btn} onClick={() => { setIsFilter(!isFilter) }} position="end">
                                                        <FilterAltIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {isFilter && (<Grid container>
                        <Box sx={{ flexGrow: 1 }} className={styles.shipment_top_bar}>
                            <form onSubmit={handleSubmit(onSubmitFilter)}>
                                <Box sx={{ flexGrow: 1 }} className={styles.shipment_filter_box}>
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} md={4}>
                                            <span className={styles.shipment_filter_box_title}>By Location</span>
                                            <TextField
                                                {...register("from")}
                                                error={errors.from !== undefined}
                                                label="From"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                defaultValue=""
                                                select
                                            >
                                                {Object.values(shipmentStartCity).map((city) => (
                                                    <MenuItem key={city} value={city}>
                                                        {city}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                {...register("to")}
                                                error={errors.to !== undefined}
                                                label="To"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                defaultValue=""
                                                select
                                            >
                                                {Object.values(shipmentDestinationCity).map((city) => (
                                                    <MenuItem key={city} value={city}>
                                                        {city}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <span className={styles.shipment_filter_box_title}>By Transporter</span>
                                            <TextField
                                                {...register("transporter")}
                                                defaultValue=""
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                size="small"
                                                select
                                                label="Transporter"
                                            >
                                                {Object.values(shipmentTransporter).map((item) => (
                                                    <MenuItem key={item} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} textAlign="center">
                                            <Button
                                                type='button'
                                                variant="outlined"
                                                onClick={() => [setIsFilter(!isFilter), setShipments(filteredAllShipments), reset()]}
                                                className={styles.shipment_filter_box_button_ml}
                                            >
                                                <Loading isLoading={false} text="Close" />
                                            </Button>
                                            <Button
                                                type='submit'
                                                variant="contained"
                                                className={styles.shipment_filter_box_button_ml}
                                            >
                                                <Loading isLoading={false} text="Search" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                    )}
                    <Grid container>
                        <Box sx={{ flexGrow: 1 }} className={styles.shipment_count_box}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.ALL ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.ALL)} >
                                        <div className={styles.shipment_count_icon_bg}><ViewInArIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{allShipments.length}</div>
                                            <span className={styles.shipment_count_text}>
                                                All Shipments</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.APP_STATUS_UNHEALTHY ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.APP_STATUS_UNHEALTHY)} >
                                        <div className={styles.shipment_count_icon_bg}><ReportIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{appStatusUnhealthyCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                App setup incomplete</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.IN_TRANSIT ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.IN_TRANSIT)} >
                                        <div className={styles.shipment_count_icon_bg}><LocalShippingIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{inTransitCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                In Transit</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.UNTRACKED ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.UNTRACKED)} >
                                        <div className={styles.shipment_count_icon_bg}><VisibilityOffIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{untrackedShipmentCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                Untracked</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.COMPLETED ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.COMPLETED)} >
                                        <div className={styles.shipment_count_icon_bg}><ModeOfTravelIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{completeCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                Completed</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.PRIORITIZED ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.PRIORITIZED)} >
                                        <div className={styles.shipment_count_icon_bg}><BeenhereIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{priorityCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                Prioritized</span>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid className={styles.shipment_componentContainer} item lg={12} md={12} xs={12}>
                            <Card className={styles.shipment_tableCard}>
                                <div className={styles.shipment_textHeadingConatiner}>
                                    <div className={styles.shipment_tableTitle} style={{ color: primaryColor }}>Shipments</div>
                                    <div style={{ color: primaryColor }}>
                                        <CSVDownload
                                            filename={`Shipments list - ${selectedCompany?.name
                                                ? selectedCompany.name + ' - '
                                                : (Auth.getInstance().getCompanySettings()?.name ? Auth.getInstance().getCompanySettings()?.name + ' - ' : '')
                                                }${getDisplayDateRange(dateRange)} - ${moment().format("DD-MMM-YYYY HH:mm a")}`}
                                            data={getShipmentsListDataForExport(shipments)}
                                        />
                                    </div>
                                </div>
                                <CardContent className={styles.shipment_cardContent}>
                                    <GenericTable
                                        data={shipments}
                                        defaultSortByField={"startTime"}
                                        headers={[
                                            { header: "" },
                                            { header: "Shipment ID" },
                                            { header: "Truck No" },
                                            { header: "Driver Name" },
                                            { header: "From" },
                                            { header: "To" },
                                            { header: "Last Updated On", sortByField: "lastLiveLocationReceivedTime" },
                                            { header: "App Status" },
                                            { header: "Trip Completion", sortByField: "shipmentCompletionPercentage" },
                                            { header: "Created At" },
                                            { header: "View Shipment" },
                                            { header: "" }
                                        ]}

                                        dataRenderer={(data, column) => {
                                            const shipment = data;
                                            switch (column) {
                                                case 0:
                                                    var style;
                                                    if (shipment.status === ShipmentStatus.COMPLETE || shipment.status === ShipmentStatus.CANCELLED) {
                                                        style = styles.shipment_details_status_not_applicable;
                                                    } else {
                                                        switch (shipment.shipmentTrackingStatus) {
                                                            case ShipmentTrackingStatus.ACTIVELY_TRACKED:
                                                                style = styles.shipment_details_status_tracked
                                                                break;
                                                            case ShipmentTrackingStatus.INTERMITTENTLY_TRACKED:
                                                                style = styles.shipment_details_status_intermittent
                                                                break;
                                                            case ShipmentTrackingStatus.MISSING_TRACKING_INFO:
                                                                style = styles.shipment_details_status_not_tracked
                                                                break;
                                                        }
                                                    }
                                                    return <FiberManualRecordIcon className={style} />;
                                                case 1:
                                                    return shipment.vanityId;
                                                case 2:
                                                    return shipment.vehicleNumber;
                                                case 3:
                                                    return shipment.driverName;
                                                case 4:
                                                    return shipment.shipmentStartCity;
                                                case 5:
                                                    return shipment.shipmentDestinationCity;
                                                case 6:
                                                    return formatTimestamp(shipment.lastLiveLocationReceivedTime);
                                                case 7:
                                                    switch (shipment.appHealth) {
                                                        case AppHealth.HEALTHY:
                                                            return (<div>
                                                                <Tooltip children={<CheckCircleIcon className={styles.shipment_details_app_status_healthy} />} title={"Healthy"} />
                                                            </div>);
                                                        case AppHealth.PERMISSIONS_PENDING:
                                                            return (<div>
                                                                <Tooltip children={<AppBlockingIcon className={styles.shipment_details_app_status_unhealthy} />} title={"Permissions pending"} />
                                                            </div>);

                                                        case AppHealth.APP_NOT_INSTALLED:
                                                            return (<div>
                                                                <Tooltip children={<MobileOffIcon className={styles.shipment_details_app_status_unhealthy} />} title={"App not installed"} />
                                                            </div>);
                                                        case AppHealth.APP_UPDATE_REQUIRED:
                                                            return (<div>
                                                                <Tooltip children={<UpgradeIcon className={styles.shipment_details_app_status_unhealthy} />} title={"App update required"} />
                                                            </div>);

                                                    }

                                                    return (<>{shipment.vanityId ? <CheckCircleIcon className={styles.shipment_details_app_status_exist} /> : <CancelSharpIcon className={styles.shipment_details_app_status_not_exist} />}</>)
                                                case 8:
                                                    return (<div>
                                                        <span className={shipment.shipmentCompletionPercentage === 100 ? styles.shipment_details_progress_bar_100 : styles.shipment_details_progress_bar}>
                                                            {shipment.shipmentCompletionPercentage.toFixed(0)}%
                                                        </span>
                                                        <ProgressBar value={shipment.shipmentCompletionPercentage} />
                                                    </div>);
                                                case 9:
                                                    return formatTimestamp(shipment.creationTime)
                                                case 10:
                                                    return (
                                                        <Button
                                                            type='button'
                                                            variant="outlined"
                                                            onClick={() => handelViewDetails(shipment)}
                                                        >
                                                            View
                                                        </Button>
                                                    );
                                                case 11:
                                                    return (
                                                        <ActionMenu
                                                            shipment={shipment}
                                                            handlePrioritised={handlePrioritised}
                                                            handleCopyShareLink={handleCopyShareLink}
                                                            isPrioritized={isPrioritized}
                                                            isCopyShareLink={isCopyShareLink}

                                                        />
                                                    );
                                            }
                                        }}
                                        pagination
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
})

export default ShipmentList;